import { styled } from "../../gatsby-theme-stitches/config"
import { Text } from "../Text/Text"

export const ProjectsList = styled("ul", {
  listStyle: "none",
  p: 0,
  m: 0,
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(304px, 1fr))",
  columnGap: "$xxl",
  rowGap: "$xxl",
  mt: "$xl",
})

export const ProjectCardVideo = styled("video", {
  backgroundSize: "cover",
  borderRadius: "10px",
  backgroundPosition: "center",
  aspectRatio: "16 / 9",
  width: "100%",
  height: 300,
  border: "1px solid $border",
  boxShadow: "0px 4px 6px rgba(20, 18, 30, 0.04)",
  transition: "all 0.2s cubic-bezier(0.4, 0, 1, 1) 0s",
  paddingBottom: "2px",
  objectFit: "cover",
})

export const ProjectCardImage = styled("div", {
  backgroundSize: "cover",
  borderRadius: "10px",
  backgroundPosition: "center",
  aspectRatio: "16 / 9",
  width: "100%",
  height: 300,
  border: "1px solid $border",
  boxShadow: "0px 4px 6px rgba(20, 18, 30, 0.04)",
  transition: "all 0.2s cubic-bezier(0.4, 0, 1, 1) 0s",
  paddingBottom: "2px",
})

export const ProjectName = styled(Text, {
  textDecoration: "underline",
  m: "0",
  pt: "$m",
})

export const ProjectDescription = styled("div", {
  color: "$text",
  " > p": {
    m: "0",
    pt: "$s",
  },
})

import React, { FC } from "react"
import { Project } from "../../@types/Project"
import { Link } from "../Link"
import { Text } from "../Text/Text"
import {
  ProjectCardImage,
  ProjectCardVideo,
  ProjectDescription,
  ProjectName,
  ProjectsList,
} from "./Projects.styles"

const ProjectCard: FC<{ project: Project }> = ({ project }) => (
  <li>
    <Link
      to={`/project/${project.slug.current}`}
      css={{
        textDecoration: "none",
        div: {
          position: "relative",
          "div > div": {
            position: "absolute",
          },
          "div > video": {
            position: "absolute",
          },
        },
        "&:hover": {
          "div > div": {
            transform: "translateY(-2px)",
            boxShadow: ` 0 1px 2px rgba(20, 18, 30, 0.03), 
                0 2px 4px rgba(20, 18, 30, 0.03), 
                0 4px 8px rgba(20, 18, 30, 0.03), 
                0 8px 16px rgba(20, 18, 30, 0.03),
                0 16px 32px rgba(20, 18, 30, 0.03), 
                0 32px 64px rgba(20, 18, 30, 0.03);`,
          },
          "div > video": {
            transform: "translateY(-2px)",
            boxShadow: ` 0 1px 2px rgba(20, 18, 30, 0.03), 
                0 2px 4px rgba(20, 18, 30, 0.03), 
                0 4px 8px rgba(20, 18, 30, 0.03), 
                0 8px 16px rgba(20, 18, 30, 0.03),
                0 16px 32px rgba(20, 18, 30, 0.03), 
                0 32px 64px rgba(20, 18, 30, 0.03);`,
          },
        },
      }}
    >
      <div>
        {project?.webm?.asset.url && (
          <ProjectCardVideo autoPlay muted loop>
            <source src={project.webm.asset.url} type="video/webm" />
            <source src={project.fallback.asset.url} type="video/mp4" />
          </ProjectCardVideo>
        )}
        {!project?.webm?.asset.url && (
          <ProjectCardImage
            css={{
              backgroundImage: `url('${project.mainImage?.asset?.url}')`,
            }}
          />
        )}
      </div>
      <ProjectName as={"p"} variant={"subtitle-1"}>
        {project.name}
      </ProjectName>
      <ProjectDescription>
        <Text as={"p"} variant={"body-2"}>
          {project.subtitle}
        </Text>
        <Text as={"p"} variant={"body-3"}>
          {project.dates}
        </Text>
      </ProjectDescription>
    </Link>
  </li>
)

export const Projects = ({ projects }: { projects: Array<Project> }) => (
  <section>
    <ProjectsList>
      {projects.map((project) => (
        <ProjectCard project={project} key={project.name} />
      ))}
    </ProjectsList>
  </section>
)

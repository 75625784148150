import React, { FC } from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"
import { Text } from "../components/Text/Text"
import { Container } from "../components/Container.styles"
import { Stack } from "../components/Stack"
import { Link } from "../components/Link"
import { HorizontalRule } from "../components/HorizontalRule"
import { graphql } from "gatsby"
import { Project } from "../@types/Project"
import { styled } from "../gatsby-theme-stitches/config"
import { AspectRatio } from "@theme-ui/components"
import { Projects } from "../components/Projects"

interface ProjectsPageProps {
  data: { allSanityProject: { nodes: Array<Project> } }
}

const Homepage: FC<ProjectsPageProps> = ({
  data: {
    allSanityProject: { nodes: projects },
  },
}) => (
  <Layout>
    <SEO title="Home" />
    <Container>
      <Stack space="m">
        <Text as={"h1"} variant={"heading-1"}>
          Software Engineer building <br />
          <em>delightful</em> web applications.
        </Text>
        <Stack space="l">
          <Text as={"h2"} variant={"body-1"}>
            Currently <em>crafting digital experiences</em> at{" "}
            <Link to="https://sumup.com">SumUp</Link>
          </Text>
          <HorizontalRule css={{ maxWidth: "600px" }} />
          <Projects projects={projects} />
        </Stack>
      </Stack>
    </Container>
  </Layout>
)

export default Homepage

export const query = graphql`
  query {
    allSanityProject(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        mainImage {
          asset {
            url
          }
        }
        name
        slug {
          current
        }
        webm {
          asset {
            url
          }
        }
        fallback {
          asset {
            url
          }
        }
        subtitle
        dates
      }
    }
  }
`
